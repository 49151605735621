import {useEffect } from 'react'
import logo from './logo.png';
import background from './background.jpg';
import hero1 from './hero1.jpg';
import hero2 from './hero2.jpg';
import img1 from './img1.jpg';
import img2 from './img2.jpg';
import img3 from './img3.jpg';
import './App.css';
import ServiceSlider from './service/ServiceSlider';
import ProductSlider from './product/ProductSlider';

function App() {
  useEffect(() => {
  const images = [
    hero1,hero2,img1,img2,img3
  ];
  let currentIndex = 0;
  
  function changeBackground() {
    const hero = document.getElementById('hero');
      currentIndex = (currentIndex + 1) % images.length;
      hero.style.backgroundImage = `url(${images[currentIndex]})`;
  }
  
  setInterval(changeBackground, 5000); // Change image every 5 seconds
  
  const menuToggle = document.getElementById('menuToggle');
  const menuDrawer = document.getElementById('menuDrawer');
  const menuClose = document.getElementById('menuClose');
  
  menuToggle.addEventListener('click', () => {
      menuDrawer.classList.add('open');
  });
  
  menuClose.addEventListener('click', () => {
      menuDrawer.classList.remove('open');
  });
  
  document.querySelector('.scroll-down').addEventListener('click', function() {
  window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
  });
  });
  
  }, [])
  

  return (
   <>
   <img src={background} className="bg"/>
    <header>
        <img src={logo} alt="Logo"/>
        <nav>
            <a href="#home">Home</a>
            <a href="#about">About Us</a>
            <a href="#services">Services</a>
            <a href="#product">Products</a>
            <a href="#contact">Contact</a>
        </nav>
        <div className="menu-toggle" id="menuToggle">&#9776;</div>
    </header>

    <div className="menu-drawer" id="menuDrawer">
        <div className="close-icon" id="menuClose">&times;</div>
        <a href="#home">Home</a>
        <a href="#about">About Us</a>
        <a href="#services">Services</a>
        <a href="#product">Products</a>
        <a href="#contact">Contact</a>
    </div>

    <section className="hero" id="hero">
        <h1>Building Your Dreams, One Brick at a Time</h1>
        <p>We provide high-quality construction services and machinery that turn your vision into reality.</p>
        <div className="scroll-down">
            <div className="scroll-icon"></div>
        </div>
    </section>

    <section className="section about" id="about">
        <h2>About Us</h2>
        <p>We are a team of dedicated professionals committed to delivering high-quality construction services and machinery. Our mission is to provide exceptional value and service to our clients.</p>
        <div className="about-contact-info">
            <div><i className="fas fa-user"></i> <strong>Name:</strong> Virender Singh</div>
            <div><i className="fas fa-phone"></i> <strong>Phone:</strong> +91 9050009784</div>
            <div><i className="fas fa-envelope"></i> <strong>Email:</strong> Virendersingh@sararsh...</div>
        </div>
    </section>


    {/* <ServiceSlider/>
    <ProductSlider/> */}

 <section className="section contact" id="contact">
        <h2>Contact Us</h2>
        <form className="contact-form">
            <input type="text" name="name" placeholder="Your Name" required/>
            <input type="email" name="email" placeholder="Your Email" required/>
            <input type="tel" name="phone" placeholder="Your Phone Number" required/>
            <textarea name="message" placeholder="Your Message" rows="5" required></textarea>
            <button type="submit">Send Message</button>
        </form>
    </section> 
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
    <footer>
        <p>&copy; 2024 Construction Company. All rights reserved.</p>
        <p>Address: Kabri Village  Panipat 132103, Haryana</p>
        <p>Follow us on <a href="#">Facebook</a>, <a href="#">Twitter</a>, and <a href="#">Instagram</a>.</p>
    </footer>
   </>
  );
}

export default App;
